<template>
  <CCardBody>

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="row">
            <!-- Country Filter -->
            <div class="col-md-4 col-filter mb-2 mb-lg-0">
              <v-select
                class="v-select-filter"
                placeholder="Country.."
                :value="$store.state.filters.country"
                :options="authCountries"
                :reduce="c => c.value"
                :searchable="false"
                @input="countryFilter"
              />
            </div>

            <!-- Search -->
            <div class="col-md col-filter mb-2 mb-lg-0">
              <CInput
                class="mb-0"
                type="search"
                placeholder="Search.."
                v-model="search"
                @input="searchFilter"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CDataTable
      hover
      striped
      :items="zipCodes"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :table-filter="{ external: true, lazy: true }"
      :column-filter="{ external: true, lazy: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No linked ZIP codes found!',
      }"
      @update:sorter-value="sorterValue"
      @update:table-filter-value="tableSearch"
      @pagination-change="paginationChange"
    >
      <!-- Distance -->
      <template #distance="{ item }">
        <td class="align-middle">
          <CIcon v-if="item._classes === 'table-warning'" name="cil-warning" size="lg" />
          {{ item.distance | FixedNumber }}
        </td>
      </template>

      <!-- Country -->
      <template #country="{item}">
        <td>
          <CIcon class="mr-1" :content="$options.flagSet[item.country.flag]" size="lg" />
          {{ item.country.label }}
        </td>
      </template>

      <!-- Zone -->
      <template #zone="{ item }">
        <td class="align-middle">
          <strong>{{ item.zcZip }}</strong>
          <small class="ml-1">{{ item.zcCity }}</small>
        </td>
      </template>

      <!-- Delivery Charge -->
      <template #delivery_charge="{item}">
        <td>{{ item.pivot.delivery_charge | toCurrency }}</td>
      </template>

      <!-- Minimum Order -->
      <template #minimum_order="{item}">
        <td>{{ item.pivot.minimum_order | toCurrency }}</td>
      </template>

      <!-- Free Charge Above -->
      <template #free_above="{item}">
        <td>{{ item.pivot.free_above | toCurrency }}</td>
      </template>

      <!-- Status -->
      <template #status="{item}">
        <td>
          <CSpinner v-if="item.updating" size="sm" color="danger" class="ml-3" />
          <CSwitch
            v-else
            labelOn="ACT"
            labelOff="INA"
            color="success"
            shape="pill"
            :disabled="!item.updatable"
            :checked.sync="item.enabled"
            @update:checked="() => updateAvailability(item)"
          />
        </td>
      </template>

      <!-- Actions -->
      <template #actions="{ item, index }">
        <td class="py-2">
          <CButton :disabled="!item.updatable" size="sm" color="danger" class="ml-1" @click="deleteItem(item, index)">
            <CIcon name="cil-trash" />
          </CButton>
        </td>
      </template>
    </CDataTable>

    <!-- Actions -->
    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>
  </CCardBody>
</template>

<script>
import _debounce from 'lodash/debounce';
import { flagSet } from '@coreui/icons'

export default {
  name: "BackendTable",
  flagSet,
  props: {
    restaurant: {
      type: Object, required: true,
    },
  },

  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "distance",        label: "Distance (km)",   filter: false, _style: "min-width: 80px" },
        { key: "country",         label: "Country",         filter: false, _style: "min-width: 120px; width: 15%" },
        { key: "zone",            label: "Delivery Zone",   filter: false, _style: "min-width: 200px; width: 20%" },
        { key: "delivery_charge", label: "Delivery Charge", filter: false, _style: "min-width: 120px; width: 20%" },
        { key: "minimum_order",   label: "Minimum Order",   filter: false, _style: "min-width: 120px; width: 20%" },
        { key: "free_above",      label: "Free Charge Above", filter: false, _style: "min-width: 120px; width: 20%" },
        { key: "status",          label: "Status",          filter: false, _style: "min-width: 96px; width: 96px;" },
        { key: "actions",         label: "Actions",         filter: false, sorter: false, _style: "min-width: 84px; width: 84px;" },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      asc: null,
      search: null,
      filteredColumn: null,

      loading: false,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,
    };
  },

  beforeMount() {
    this.searchFilter = _debounce(() => {
      this.getAllItems();
    }, 1000);
  },

  async mounted() {
    this.activePage =  this.queryPage;
    await this.getAllItems();
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: 'RestaurantLinkedZIPCodes', params: { restaurant_id: this.restaurantId }, query: { page: this.activePage } });

      this.onTableChange();
    },
  },

  computed: {
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() { return parseInt(this.$route.query.page) || 1; },
    host() { return this.$backend.HOST; },
    restaurantId () { return this.$route.params.restaurant_id },
    maxDistance() {
      return parseFloat(process.env.VUE_APP_DELIVERY_ZONE_DISTANCE_WARNING_LIMIT);
    },
    zipCodes() {
      return this.loadedItems
        .map((el) => {
          return {
            ...el,
            country: this.authCountries.find((c) => c.value === el.zcCountryId),
            distance: this.$globalFunc.haversineDistance(
              { lat: this.restaurant.resto_lat, lng: this.restaurant.resto_lng },
              { lat: el.zcLat, lng: el.zcLng }
            ),
          };
        })
        .map((el) => {
          let classes = el.distance > this.maxDistance ? "table-warning" : "";
          classes += el.pivot?.disabled ? " strikeout " : "";

          return {
            ...el,
            enabled: !(el.pivot.disabled),
            updatable: !(el.zcCountryId == this.restaurant.countryId && el.zcZip == this.restaurant.restaurant_postcode),
            updating: false,
            _classes: classes,
          };
        });
    },
  },

  methods: {
    async getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.RESTAURANT_LINKED_ZIPCODES.GET_ALL.replace("{restaurant_id}", this.restaurantId)),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          country: this.$store.state.filters.country,
          orderBy: this.orderBy,
          asc: this.asc,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== ""
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios
        .get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data;
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.asc = item.column ? item.asc : null; //column null ise asc=null olsun
      this.getAllItems();
    },
    tableSearch(search) {
      this.search = search;
      this.getAllItems();
    },

    async updateAvailability(item) {
      item.updating = true;

      try {
        const response = await this.$axios.put(
          this.$backend.RESTAURANT_LINKED_ZIPCODES.UPDATE_AVAILABILITY.replace("{restaurant_id}", this.restaurantId).replace("{id}", item.id), {
          disabled: !item.enabled,
        });

        this.loadedItems
          .filter(area => area.zcZip === item.zcZip)
          .forEach(area => area.pivot.disabled = !item.enabled);

        this.$toast.success(response.data.message);
      } catch (error) {
        item.enabled = !item.enabled;

        var apiErrorMessage = error.response?.data?.message;
        this.$toast.error(apiErrorMessage ?? error, { timeout: 0 });
      } finally {
        item.updating = false;
      }
    },

    deleteItem(item, index) {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to delete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.loading = true;
            this.$axios.delete(
              this.$backend.RESTAURANT_LINKED_ZIPCODES.DELETE.replace("{restaurant_id}", this.restaurantId).replace("{id}", item.pivot.id)
            )
              .then((response) => {
                this.$delete(this.zipCodes, index);
                this.showErrorAlert = false
                this.successAlertMessage = {itemName: `${item.zcZip} - ${item.zcCity}`, message: 'linked ZIP code deleted.'}
                this.dismissSuccessAlert = this.dismissSecs
                this.total--
              })
              .catch((error) => {
                this.errorAlertMessage = error.response.data
                this.showErrorAlert = true
              })
              .finally(() => {
                window.scrollTo(0,0);
                this.loading = false;
              });
            // ---------------
          }
        },
      });
    },

    // Filters
    countryFilter(value) {
      this.$store.commit("setCountryFilter", value);
      this.getAllItems();
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.$store.commit("setCountryFilter", null);
        this.search = "";
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },
  },
};
</script>
