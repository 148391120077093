<template>
  <CRow>
    <CCol sm="12">
      <CCard v-if="restaurant">
        <CCardHeader>
          <CIcon name="cil-location-pin" />
          <h5 class="d-inline ml-2">
            <h3 class="d-inline"><CBadge color="secondary">{{ restaurant.restaurant_name | uppercase }}</CBadge></h3>
            Linked ZIP Codes
          </h5>
          <div class="card-header-actions">
            <CButton color="link" :to="{ name: 'View Restaurant', params: { 'id': restaurantId } }" class="ml-2">
              <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back To Restaurant
            </CButton>

            <CButton color="primary" :to="{ name: 'AddNewLinkedZIPCode'}">
              <CIcon name="cil-plus" class="align-bottom" /><strong>Add New</strong> Linked ZIP Code
            </CButton>
          </div>
        </CCardHeader>

        <BackendTable :restaurant="restaurant" />
      </CCard>

      <div v-else>
        <CSpinner v-if="loading" color="primary" />
        <h6 v-else>Restaurant not found!</h6>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import BackendTable from "./BackendTable";

export default {
  name: "RestaurantLinkedZIPCodes",
  components: {
    BackendTable,
  },
  data() {
    return {
      loading: true,
      restaurant: null,
    };
  },
  async mounted() {
    await this.getRestaurant();
  },
  computed: {
    restaurantId() { return this.$route.params.restaurant_id; },
  },
  methods: {
    async getRestaurant() {
      await this.$axios
        .get(this.$backend.RESTAURANT.GET_NAME.replace("{id}", this.restaurantId), {
          params: {
            fields: 'restaurant_postcode,resto_lat,resto_lng,countryId'
          }
        })
        .then((response) => {
          this.restaurant = response.data;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
